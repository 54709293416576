import React, { Component } from 'react'
import './ProjectBox.css'

export class ProjectBox extends Component {
  render() {
    const { project } = this.props;

    return (
      <>
        <div className='project-box-outer'>

          {
            // show image if project has one
            project.image !== "" ? (
              <div className='project-img'>
                <img src={`./images/${project.image}`} alt='' />
              </div>
            ) : null
          }

          <table><tbody>
            <tr>
              <td>
                <h3>{project.name}</h3>
              </td>

              <td className='projectLinks'>

                {
                  project.links.map((link, index) => (
                    <a href={link.url} target="_blank" rel="noreferrer" key={index}>
                      <img className="socialButton" src={global.config.getSrc(link.image)} alt='' />
                    </a>
                  ))
                }

              </td>

            </tr>
            <tr><td colSpan="2">

              {project.description}

            </td></tr>

            <tr><td>

              {
                project.tags.map((tag, index) => {
                  const sanitizedTag = tag.toLowerCase().replace('#', 'sharp').replace(/[^a-z]/g, '');
                  return (
                    <div className={`tag ${sanitizedTag}`} key={index}>
                      {tag}
                    </div>
                  );
                })
              }

            </td></tr>

          </tbody></table>

        </div>

      </>
    )
  }
}

export default ProjectBox