import React, { Component } from 'react'
import './WelcomePage.css'
import pic from './images/me.jpg'
import MyModal from "./myModal";

/*
icons: https://www.iconfinder.com/iconsets/eon-social-media-contact-info-2
game icon: https://www.iconfinder.com/iconsets/videogames-4
*/

export class WelcomePage extends Component {
    render() {
        return (
            <>
                <div className="welcome-body">
                    <div className="content">

                        <div className="row">

                            <div className="column left">
                                <div className="center">
                                    <h1>Marcus Olivas</h1>
                                    <p>Some text..</p>

                                    <a href='https://www.linkedin.com/in/marcus-olivas-142471263/' target="_blank" rel="noreferrer"> <img className="socialButton" src={global.config.getSrc('linkedin')} alt='' /></a>

                                    <a href='https://github.com/markviews' target="_blank" rel="noreferrer"> <img className="socialButton" src={global.config.getSrc('github')} alt='' /></a>

                                    <a href='https://drive.google.com/file/d/1tlExRq2C6Y9hbk573BFG5qeg-gSyxpki/view?usp=sharing' target="_blank" rel="noreferrer"> <img className="socialButton" src={global.config.getSrc('cv')} alt='' /></a>

                                    {/* <MyModal settings = {{
                                "button": <img className="socialButton" src={global.config.getSrc('email')} alt='' />,
                                "title": "Email",
                                "content": "i'll type this later.. need to make sure it's safe from bots.."
                            }}/> */}

                                </div>
                            </div>

                            <div className="column right">
                                <img src={pic} alt='' />
                            </div>

                        </div>





                    </div>
                </div>

            </>
        )
    }
}

export default WelcomePage