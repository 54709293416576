import WelcomePage from "./WelcomePage";
import ProjectsPage from "./ProjectsPage";
import './config'
import React, { useEffect } from 'react';

function App() {

  useEffect(() => {
    document.title = 'Marcus Olivas';
  }, []);

  global.config.theme = localStorage.getItem("theme");
  if (global.config.theme == null) global.config.theme = "dark"

  /*
  const toggleTheme = () => {

    // swap
    if (global.config.theme === "dark") {
      global.config.theme = "light";
    } else {
      global.config.theme = "dark";
    }

    // save
    document.getElementById("theme").className = global.config.theme
    localStorage.setItem("theme", global.config.theme)

    // swap pictures
    if (global.config.theme === "dark") {
      Array.from(document.querySelectorAll('img.socialButton')).forEach(img => {
        img.src = img.src.replace("_dark.png", ".png")
      });
    } else {
      Array.from(document.querySelectorAll('img.socialButton')).forEach(img => {
        
        img.src = img.src.replace(".png", "_dark.png")
      });
    }

  }
*/
  return (
    <>
      <div id="theme" className={global.config.theme} /* onClick={toggleTheme} */ >
        <WelcomePage/>
        <ProjectsPage/>
      </div>

    </>
  );

}

export default App;
